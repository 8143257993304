import axios from 'axios';
import { Site } from './types';

const api = axios.create({
  // baseURL: 'http://localhost:3006/ser', 
  baseURL: 'https://coopacha.com/ser'
});

// Helper function to get the JWT token from local storage
const getToken = (): string | null => {
  return localStorage.getItem('token');
};

// Add a request interceptor to include the token in headers
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getSites = async (): Promise<Site[]> => {
  const response = await api.get('/sites');
  return response.data;
};

export const getSiteByName = async (name: string, group: number): Promise<Site> => {
  const response = await api.get(`/sites/${name}/${group}`);
  return response.data;
};

export const updateSite = async (ID: number, site: Site): Promise<Site> => {
  const response = await api.put(`/sites/${ID}`, site);
  return response.data;
};

export const deleteSite = async (name: string, group: number): Promise<void> => {
  await api.delete(`/sites/${name}/${group}`);
};

export const createSite = async (site: Site): Promise<void> => {
  await api.post('/sites', site);
};

export {};
