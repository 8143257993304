import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSiteByName, getSites, deleteSite } from '../api'; // Import deleteSite function
import { Site } from '../types';
import SiteForm from './SiteForm';
import SiteList from './SiteList'; // Import SiteList component
import { Typography, Grid, Button, Box } from '@mui/material'; // Import Box component

const SitePage: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();
  const [sites, setSites] = useState<Site[]>([]);
  const [site, setSite] = useState<Site | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<number>(1); // Default group to 1

  // Fetch all sites data
  const fetchSites = async () => {
    try {
      const sites = await getSites();
      setSites(sites);
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  // Fetch site data by name and group
  const fetchSite = async () => {
    try {
      const site = await getSiteByName(name ?? "Skrill", selectedGroup);
      setSite(site);
    } catch (error) {
      console.error(`Error fetching site with name ${site?.Name} and group ${selectedGroup}:`, error);
    }
  };

  // Fetch site data on component mount and when the route or selected group changes
  useEffect(() => {
    fetchSites();
    fetchSite();
  }, [name, selectedGroup]);

  // Handle group change
  const handleGroupChange = (group: number) => {
    setSelectedGroup(group);
  };

  // Handle delete site
  const handleDelete = async () => {
    if (site) {
      try {
        await deleteSite(site.Name, selectedGroup);
        navigate('/');
      } catch (error) {
        console.error(`Error deleting site with name ${site.Name} and group ${selectedGroup}:`, error);
      }
    }
  };

  // Render loading message while site data is being fetched
  if (!site) {
    return <Typography>Loading...</Typography>;
  }

  // Get unique groups from the sites
  const groups = Array.from(new Set(sites.map(site => site.Group)));

  return (
    <Grid container spacing={3}>
      {/* Sidebar with SiteList component */}
      <Grid item xs={3}>
        <SiteList />
      </Grid>
      {/* Main content area with site name and SiteForm component */}
      <Grid item xs={8}>
        <Typography variant="h4">{site.Name}</Typography>
        <SiteForm site={site} groups={groups} selectedGroup={selectedGroup} onGroupChange={handleGroupChange} />
        <Box mt={2}>
          <Button variant="contained" color="secondary" onClick={handleDelete}>
            Delete Site
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SitePage;
