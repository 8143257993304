import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation hook
import { getSites } from '../api';
import { Site } from '../types';
import { Box, Drawer, List, ListItem, ListItemText, Typography, CssBaseline, AppBar, Toolbar } from '@mui/material';

const drawerWidth = 240;

const SiteList: React.FC = () => {
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useState<string | null>("Skrill"); // State for selected item
  const location = useLocation();

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const sites = await getSites();
        const uniqueSites = sites.filter((site, index, self) => 
          index === self.findIndex(s => s.Name === site.Name)
        );
        setSites(uniqueSites);
      } catch (error) {
        console.error('Error fetching sites:', error);
      }
    };

    fetchSites();
  }, []);

  useEffect(() => {
    // Extract the site name from the pathname
    const pathname = location.pathname;
    const siteName = pathname.substring(1); // Remove leading slash

    setSelectedSite(siteName || "Skrill"); // Update selected site state
  }, [location.pathname]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Site Management
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {sites.map((site) => (
              <ListItem
                button
                key={site.ID}
                component={Link}
                to={`/${site.Name}`}
                selected={selectedSite === site.Name} // Apply selected style based on state
                sx={{ backgroundColor: selectedSite === site.Name ? '#b3e5fc' : 'inherit' }} // Custom background color
              >
                <ListItemText primary={site.Name} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};

export default SiteList;
